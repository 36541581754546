import request from '@/utils/request'

const speedy_itemApi = {
  speedy_item_list: '/speedy_item/',
  speedy_item_create: '/speedy_item/',
  speedy_item_detail_list: '/speedy_item_detail/',
  speedy_item_excel_list: '/speedy_item_excel/'
}

/**
 * 列表
 */
export function speedy_item_list (parameter) {
  return request({
    url: speedy_itemApi.speedy_item_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
export function speedy_item_detail_list (parameter) {
  return request({
    url: speedy_itemApi.speedy_item_detail_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 导出
 */
export function speedy_item_excel_list (parameter) {
  return request({
    url: speedy_itemApi.speedy_item_excel_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}
/**
 * 添加
 */
export function speedy_item_create (parameter) {
  return request({
    url: speedy_itemApi.speedy_item_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
