<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="handelsubmit"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="corporation_id" v-show="false">
              <a-input v-decorator="['corporation_id']" />
            </a-form-item>
            <a-form-item
              label="企业名称"
              v-bind="formItemLayout"

            >
              <a-auto-complete
                v-decorator="['corporation', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                :data-source="dataSource"
                placeholder="请输入"
                @select="onSelect"
                @search="onSearch"
                @change="onChange"
              />
              <div class="ant-form-explain" v-if="!!tip">  {{ tip }} </div>
            </a-form-item>
            <a-form-item
              v-bind="formItemLayout"
              label="物品"
              :required="false"
            >
              <a-row :gutter="[16,16]">
                <a-col :span="4">
                  <a-select
                    placeholder="请选择物品一级类别"
                    @change="handlecategoryChange"
                    @select="handlecategoryEntranceSelect"
                    v-decorator="[
                      `entrance.category`,
                      {
                        validateTrigger: ['change', 'blur'],
                        rules: [
                          {
                            required: false,
                            message: '请选择物品类别'
                          },
                        ],
                      },
                    ]">
                    <a-select-option v-for="item in entrance_list" :key="item.key" :value="item.key">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col :span="4">
                  <a-select
                    placeholder="请选择物品二级类别"
                    v-decorator="[
                      `entrance.subcategory`,
                      {
                        rules: [
                          {
                            required: false,
                            message: '请选择物品类别'
                          },
                        ],
                      },
                    ]">
                    <a-select-option v-for="son in father" :key="son.title" :value="son.key">
                      {{ son.label }}
                    </a-select-option>
                  </a-select>

                </a-col>
                <a-col :span="4">
                  <a-input v-decorator="[`entrance.name`, { rules: [{required: false, message: '必填项，请填写信息'}]}]" placeholder="请填写名称"></a-input>
                </a-col>
                <a-col :span="4">
                  <a-input v-decorator="[`entrance.unit`, { rules: [{required: false, message: '必填项，请填写信息'}]}]" placeholder="请填写货物单位"></a-input>

                </a-col>
                <a-col :span="4">
                  <a-input v-decorator="[`entrance.count`, { rules: [{required: false, message: '必填项，请填写信息'}]}]" placeholder="请填写货物数量"></a-input>
                </a-col>
                <a-col :span="24">
                  <a-textarea v-decorator="[`entrance.remark`, { rules: [{required: false, message: '必填项，请填写信息'}]}]" placeholder="请填写备注"></a-textarea>
                </a-col>
                <a-col :span="4">
                  <a-input v-show="false" v-decorator="[`entrance.action`, {initialValue: 3, rules: [{required: false, message: '必填项，请填写信息'}]}]" placeholder="请填写名称"></a-input>
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { corporation_list } from '@/api/corporation'
// 表单字段
const fields = ['id']
let id = 0
let exit_id = 0
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      tip: '',
      dataSource: [],
      entrance: {},
      exit: {},
      entrance_list: this.$Dictionaries.GateWayItem,
      exit_list: this.$Dictionaries.GateWayItem,
      form: this.$form.createForm(this),
      father: null,
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 }
        }
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 24 }
        }
      }
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    this.form.getFieldDecorator('keys_entrance', { initialValue: [], preserve: true })
    this.form.getFieldDecorator('keys_exit', { initialValue: [], preserve: true })
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    handelsubmit () {
      if (!this.tip) {
        this.$emit('ok')
      } else {
        this.$message.warning(this.tip)
      }
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
      corporation_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的企业'
        } else {
          this.tip = ''
        }
        console.log('我是企业列表', reslut)
        this.dataSource = !searchText ? [] : reslut.map((item) => item.name)
      })
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      const corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id
      this.form.setFieldsValue({
        corporation_id: corporation_id
      })
      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    handlecategoryChange (value) {
      this.father = this.$Dictionaries.GateWayItem.filter((item) => { return item.value === value })[0].children
      console.log(this.father)
    },
    handlecategoryEntranceSelect () {
      const { form } = this
      console.log(form.getFieldValue('entrance'))
      const result = form.getFieldValue('entrance')
      result.subcategory = undefined
      form.setFieldsValue({ 'entrance': result })
    },
    remove (k) {
      console.log(k, '我是key')
      const { form } = this
      // can use data-binding to get
      const keys_entrance = form.getFieldValue('keys_entrance')
      // We need at least one passenger
      if (keys_entrance.length === 1) {
        return
      }

      // can use data-binding to set
      form.setFieldsValue({
        keys_entrance: keys_entrance.filter(key => key !== k)
      })
    },

    add () {
      const { form } = this
      // can use data-binding to get
      const keys_entrance = form.getFieldValue('keys_entrance')
      const nextkeys_entrance = keys_entrance.concat(id++)
      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        keys_entrance: nextkeys_entrance
      })
    },
    remove_exit (k) {
      const { form } = this
      // can use data-binding to get
      const keys_exit = form.getFieldValue('keys_exit')
      // We need at least one passenger
      if (keys_exit.length === 1) {
        return
      }

      // can use data-binding to set
      form.setFieldsValue({
        keys_exit: keys_exit.filter(key => key !== k)
      })
    },

    add_exit () {
      const { form } = this
      // can use data-binding to get
      const keys_exit = form.getFieldValue('keys_exit')
      const nextkeys_exit = keys_exit.concat(exit_id++)
      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        keys_exit: nextkeys_exit
      })
    }
  }
}
</script>
